import { intl } from 'lib/i18n';
import { MoreHorizontalURL } from 'resources/assets/icons';

export * from './form';

export const APP_PLATFORM = 'mTab';
export const APPLICATION_IDENTIFIER = 'platform-home-portal-client';
export const APPLICATION_ID = 'HOME_PORTAL';
export const PUBLIC_URL = process.env.PUBLIC_URL ?? '';
export const ENABLE_ANALYTICS = process.env.REACT_APP_ENABLE_ANALYTICS && process.env.REACT_APP_ENABLE_ANALYTICS.toLowerCase() !== 'false';
export const INDUSTRY_ATTRIBUTE_KEY = 'industry';
export const INDUSTRY_ATTRIBUTE_MAX = 3;

export const TOS_URL = 'https://www.mtab.com/terms-of-service';
export const PRIVACY_POLICY_URL = 'https://www.mtab.com/privacy-policy';
export const ZENDESK_SCRIPT_URL = process.env.REACT_APP_ZENDESK_SCRIPT_URL;

export const ANTD_SORT_ORDER_ASCENDING = 'ascend';
export const ANTD_SORT_ORDER_DESCENDING = 'descend';
// prevents antd table from sorting back to an unsorted order - its from the docs but not very clear so extracted to a constant
export const ANTD_SORT_ORDER_NO_UNSORTED_ORDER = ['ascend', 'descend', 'ascend'];
export const TOOLTIP_DELAY_LONG = 0.4; // 400ms but antd uses seconds to represent it

// limit used when effectively we want no meaningful limit - but still prevent users uploading as much data as they like
export const MAX_INPUT_LENGTH_UNLIMITED = 5000;

export const INDUSTRY_ATTRIBUTE_VALUE_OTHER = {
  keyId: INDUSTRY_ATTRIBUTE_KEY,
  valueId: 'OTHER',
  value: intl.formatMessage({ defaultMessage: 'Other', description: 'Non classifiable option' }),
  iconUrl: MoreHorizontalURL
};
