import { intl } from 'lib/i18n';

export const CONTACT_URL = 'https://www.mtab.com/contact';

export const TUTORIALS_LABEL = intl.formatMessage({
  defaultMessage: 'Tutorials',
  description: 'Tutorials menu item label'
});

export const CONTACT_LABEL = intl.formatMessage({
  defaultMessage: 'Contact',
  description: 'Contact menu item label'
});
