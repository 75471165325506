import React, {
  lazy,
  Suspense,
  useLayoutEffect,
  useRef
} from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import { SplashScreen } from '@mtabnpd/mtab-platform-core';
import { Authed } from '@mtabnpd/mtab-client-auth-js';

import {
  ROUTE_IMPERSONATION,
  ROUTE_SETTINGS,
  ROUTE_TENANT_SWITCHER,
  ROUTE_DEFAULT,
  ROUTE_SETTINGS_USER,
  ROUTE_FEATURED,
  ROUTE_REDIRECT_REGION,
  ROUTE_FEED_ALL,
  ROUTE_FEED_TUTORIALS,
  ROUTE_FEED_SEARCH,
  ROUTE_MTAB_PLUS_BENEFITS
} from 'constants/routes';

import { TrueUser } from './routeGuards';
import { UserRegionRedirect } from './redirect';
import { useFeatureFlag } from '../lib/features';

const Home = lazy(() => import(/* webpackChunkName: 'route-home' */ './home'));
const UserSettings = lazy(() => import(/* webpackChunkName: 'route-user-settings' */ './userSettings'));
const Impersonation = lazy(() => import(/* webpackChunkName: 'route-impersonation' */ './impersonation'));
const TenantSwitcher = lazy(() => import(/* webpackChunkName: 'route-tenantSwitcher' */ './tenantSwitcher'));
const Layout = lazy(() => import(/* webpackChunkName: 'route-layout' */ './layout'));
const Tutorials = lazy(() => import(/* webpackChunkName: 'route-tutorials' */ './tutorials'));
const Search = lazy(() => import(/* webpackChunkName: 'route-searc' */ './search'));
const MtabPlusBenefits = lazy(() => import(/* webpackChunkName: 'route-mTabPlusBenefits' */ './mTabPlusBenefits'));

export function Outlet() {
  const location = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);
  const { flags } = useFeatureFlag();

  useLayoutEffect(() => {
    const { current: el } = contentRef;
    if (el) {
      el.scrollTop = 0;
    }
  }, [location.pathname]);

  return (
    <div ref={contentRef} className="App__content">
      <Suspense fallback={<SplashScreen className="c-suspense-fallback" visible delay={500} />}>
        <Routes>
          <Route path={ROUTE_FEATURED} element={<Navigate to={ROUTE_DEFAULT} />} />
          <Route path={ROUTE_REDIRECT_REGION} element={<UserRegionRedirect />} />

          <Route
            path={ROUTE_DEFAULT}
            element={<Authed />}
          >
            <Route element={<Layout />}>
              {/*
                PLAT-2513 - feature flagging tutorials tab only
                https://mtab.atlassian.net/browse/PLAT-2513
              */}
              {!flags.ENABLE_FEED_TUTORIALS && (
                <Route path={ROUTE_FEED_TUTORIALS} element={<Tutorials />} />
              )}

              <Route path="" element={<Navigate to={ROUTE_FEED_ALL} />} />
              <Route path={ROUTE_FEED_SEARCH} element={<Search />} />
              <Route path=":feedCategory" element={<Home />} />
              <Route path={ROUTE_MTAB_PLUS_BENEFITS} element={<MtabPlusBenefits />} />
            </Route>
          </Route>

          <Route element={<Authed />}>
            <Route path={ROUTE_IMPERSONATION} element={<Impersonation />} />
            <Route path={ROUTE_TENANT_SWITCHER} element={<TenantSwitcher />} />

            <Route path={ROUTE_SETTINGS} element={<TrueUser />}>
              <Route path="" element={<Navigate to={ROUTE_SETTINGS_USER} />} />
              <Route path={`${ROUTE_SETTINGS_USER}/*`} element={<UserSettings />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}
