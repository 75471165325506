import { createApi } from '@reduxjs/toolkit/query/react';

import { query } from './query';

export enum Tag {
  TUTORIALS = 'TUTORIALS',
  FEATURED = 'FEATURED',
  USER = 'USER',
  FEED = 'FEED',
  SPOTLIGHT = 'SPOTLIGHT',
  NAVIGATION = 'NAVIGATION'
}

export const UserContentTags = [Tag.SPOTLIGHT];
export const DEFAULT_TTL_SECONDS = 60; // seconds

export const api = createApi({
  reducerPath: 'api',
  baseQuery: query,
  tagTypes: Object.values(Tag),
  endpoints: () => ({ /* endpoints are injected */})
});
